export async function getRecs(input_genes, api_root) {
  // get recommendations from API
  const algo = "DP2.1";
  const numOfRecs = 5;

  let resp = fetch(
    `${api_root}/recommend/?numOfRecs=${numOfRecs}&algo=${algo}`,
    {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({ geneslist: input_genes, diseaseslist: [] }),
      method: "POST",
    }
  )
    .then((response) => response.json())
    .then((data) => data.recs)
    .then((recs) => recs.map((r) => r.geneInfo));

  return resp;
}
