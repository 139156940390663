import { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";

const elabRespGene = async function (r) {
  const elab_r = r.map(({ genesymbol, approvedname, alias }) => {
    return {
      value: genesymbol,
      label: `${genesymbol} | ${approvedname}`,
    };
  });

  return {
    options: elab_r,
  };
};

const elabRespDisease = async function (r) {
  const elab_r = r.map(({ code, name }) => {
    // console.log(code);
    return {
      value: code,
      label: `${code} | ${name}`,
    };
  });

  return { options: elab_r };
};

const searchGenes = (q, token, api_root) => {
  if (q.length > 0) {
    const resp = fetch(
      `${api_root}/genes/searchGenesByCodeOrName/?q=${q}&searchInGeneSymbol=true&searchInGeneAlias=true&limit=10&offset=0`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    const r = resp.then((response) => response.json()).then(elabRespGene);

    return r;
  } else {
    return elabRespGene([]);
  }
};

const searchDiseases = (q, token, api_root) => {
  if (q.length > 0) {
    const resp = fetch(
      `${api_root}/diseases/searchDiseaseByName/?q=${q}&limit=10&offset=0`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    const r = resp.then((response) => response.json()).then(elabRespDisease);

    return r;
  } else {
    return elabRespDisease([]);
  }
};

function ItemSearchMulti({
  q,
  token,
  api_root,
  item_type,
  onOutputChangeHandle,
  defaultInput,
  demo,
}) {
  function formatOutputItems(x) {
    const o = x.map((a) => a.value);
    return o.slice(0, 100);
  }

  const loadOptions = (x) => {
    if (item_type === "gene") {
      return searchGenes(x, token, api_root);
    } else if (item_type === "disease") {
      return searchDiseases(x, token, api_root);
    }
  };

  let placeholder = "";
  if (item_type === "disease") {
    placeholder = "Enter a disease name or MESH id...";
  } else if (item_type === "gene") {
    placeholder = "Enter a gene symbol or gene name...";
  }

  return (
    <AsyncPaginate
      autoFocus={false}
      isMulti
      placeholder={placeholder}
      defaultInputValue={q}
      loadOptions={(x) => loadOptions(x)}
      onChange={(x) => {
        onOutputChangeHandle(formatOutputItems(x));
      }}
      debounceTimeout={300}
      isDisabled={demo}
      // on error wait some time and retry
    />
  );
}

function ItemSearchSingle({ q, token, api_root, onOutputChangeHandle }) {
  return (
    <AsyncPaginate
      autoFocus={true}
      defaultInputValue={q}
      placeholder="type to search..."
      loadOptions={(x) => searchGenes(x, token, api_root)}
      onChange={(x) => onOutputChangeHandle(x)}
      debounceTimeout={300}
    />
  );
}

function ItemSelector({ q, token, api_root, onchange, isDisabled }) {
  const [options, setOptions] = useState([]);
  const [selOption, setSelOption] = useState();

  useEffect(() => {
    searchGenes(q, token, api_root).then((opts) => {
      setOptions(opts);
      setSelOption(opts[0]);
      onchange(opts[0] ? opts[0].value : "");
    });
  }, [q, token, api_root, onchange]);

  return (
    <AsyncPaginate
      isOptionSelected={true}
      defaultOptions={options}
      isDisabled={isDisabled}
      onChange={(e) => {
        onchange(e.value);
        setSelOption({ value: e.value, label: e.value });
      }}
      value={selOption}
      placeholder="type to search..."
      loadOptions={(x) => searchGenes(x, token, api_root)}
      debounceTimeout={300}
    />
  );
}

export default ItemSelector;

export { ItemSearchMulti, ItemSearchSingle, ItemSelector };
