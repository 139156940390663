import { ItemSearchMulti } from "./ItemSearch";
import { Card } from "react-bootstrap";

export function InputBox({ api_root, setInputGenes, inputGenes }) {
  // input genes box
  return (
    <Card className="p-2 h-100 bg-light">
      <h2>Input Genes</h2>
      <div className="border-bottom pb-2 mb-3">
        Genes related to your research
      </div>
      <ItemSearchMulti
        onOutputChangeHandle={setInputGenes}
        api_root={api_root}
        item_type="gene"
        multi
      ></ItemSearchMulti>
    </Card>
  );
}
