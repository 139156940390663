import { Card, Badge, Button } from "react-bootstrap";
import { ArrowRight, ArrowRightSquare } from "react-bootstrap-icons";

export function OutputBox({ outputGenes, gr_signup_url }) {
  // output genes box
  let cardContent = (
    <div className="d-flex align-items-center h-100 ">
      <div className="mx-auto text-danger">Insert some genes and click GO!</div>
    </div>
  );

  if (outputGenes.length > 0) {
    cardContent = (
      <>
        <h2>Output Genes</h2>
        <div className="border-bottom pb-2 mb-3">
          You should also check these genes
        </div>
        <Card className="px-2 pt-1 ">
          {outputGenes.map((g) => {
            return (
              <Badge key={g.genesymbol} bg="primary" className="mb-1">
                {g.genesymbol} | {g.approvedname}
              </Badge>
            );
          })}
        </Card>
        <a
          href={gr_signup_url}
          target="_blank"
          rel="noreferrer"
          className="btn btn-info mt-2 "
        >
          Subscribe For More Features...
        </a>
      </>
    );
  }

  return <Card className="flex p-2 h-100 bg-light">{cardContent}</Card>;
}
