import { useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { InputBox } from "./InputBox";
import { OutputBox } from "./OutputBox";
import { getRecs } from "./utils";

function App({ api_root, gr_signup_url }) {
  // main app

  const [inputGenes, setInputGenes] = useState([]);
  const [outputGenes, setOutputGenes] = useState([]);

  const onSubmit = (inputGenes) => {
    // console.log("submit");
    // console.log("inputGenes", inputGenes);
    getRecs(inputGenes, api_root).then((recs) => setOutputGenes(recs));
  };

  return (
    <Card className="p-2 h-100">
      <Row className="g-2">
        <Col md="5">
          <InputBox
            api_root={api_root}
            setInputGenes={setInputGenes}
            inputGenes={inputGenes}
          ></InputBox>
        </Col>
        <Col md="2">
          <Button
            variant="success"
            className="h-100 w-100 my-0"
            onClick={() => onSubmit(inputGenes)}
            disabled={inputGenes.length === 0}
            hidden={outputGenes.length > 0}
          >
            GO
          </Button>
          <Button
            variant="danger"
            className="h-100 w-100 my-0"
            onClick={() => {
              // console.log("reset");
              setOutputGenes([]);
              // setInputGenes([]);
            }}
            hidden={outputGenes.length === 0}
          >
            RESET
          </Button>
        </Col>
        <Col md="5">
          <OutputBox
            outputIsActive={outputGenes.length > 0}
            outputGenes={outputGenes}
            gr_signup_url={gr_signup_url}
          ></OutputBox>
        </Col>
      </Row>
    </Card>
  );
}

export default App;
